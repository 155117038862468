import Map from './components/Map'
import React, {useEffect} from 'react'
import {getMapData, getTranslations, cancelRequests} from './api'
import {buildQuery, getParamValue, checkLanguageInUrl} from './utils'

import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'

import {ThemeProvider} from '@mui/material/styles'
import {theme} from './theme'

import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfBasic} from './store/features/Basic'
import {setFieldValueOfMap} from './store/features/Map'
import LocationDetail from './components/LocationDetail'
import useCoordinates from './hooks/useCoordinates'
import useHasChanges from './hooks/useHasChanges'
import useFilterOptions from './hooks/useFilterOptions'
import {useTranslation} from "react-i18next"
import useFilterChanged from './hooks/useFilterChanged'
import useSelectedLanguage from './hooks/useSelectedLanguage'
import useToggleLocationsList from './hooks/useToggleLocationsList'
import Cookies from 'universal-cookie'

import Usercentrics from '@usercentrics/cmp-browser-sdk'
import {ILocationsList} from './interface/locationsList'
import {setFieldValueOfLocation} from './store/features/Location'
import {IPlacesList} from './interface/placesList'
import useSelectedPlace from './hooks/useSelectedPlace'
import useAppVersion from './hooks/useAppVersion'

const cookies = new Cookies()

const App = () => {

    const dispatch = useDispatch()
    const isIFrame = Boolean(getParamValue('isIframe')) || false

    // Basic - isLoading
    const setIsLoading = (value) => {
        dispatch(setFieldValueOfBasic({
            field: 'isLoading',
            value
        }))
    }

    const {setSelectedLanguage} = useSelectedLanguage()

    // Location - toggle locations list
    const {toggleLocationsList} = useToggleLocationsList()

    // Load translations
    const {i18n} = useTranslation()

    // App version
    const {setAppVersion} = useAppVersion()

    const handleAppVersion = () => {
        const appVersion = cookies.get('appVersion')
        setAppVersion(appVersion)
    }

    useEffect(() => {
        const urlLang = checkLanguageInUrl()
        const lang = urlLang ? urlLang : typeof cookies.get('gw_lang') !== 'undefined' ? cookies.get('gw_lang') : checkLanguageInUrl() || getParamValue('lang') || localStorage.getItem('lang') || 'en'

        getTranslations('en').then(result => {
            i18n.removeResourceBundle('en', 'translation')
            i18n.addResourceBundle('en', 'translation', result, true, true);
        })
        getTranslations('sk').then(result => {
            i18n.addResourceBundle('sk', 'translation', result, true, true)
        })
        getTranslations('pl').then(result => {
            i18n.addResourceBundle('pl', 'translation', result, true, true)
            handleAppVersion()
        })

        // language orchestration
        setSelectedLanguage(lang)
        localStorage.setItem('lang', lang)
        i18n.changeLanguage(lang)
    }, [])

    // Map - bounds, zoomLevel
    const bounds = useSelector((state) => state.map.bounds)

    // Map - coordinates
    const {coordinates} = useCoordinates()

    // Map - has changes
    const {hasChanges, setHasChanges} = useHasChanges()

    // Map - filter has changed
    const {filterChanged} = useFilterChanged()
    const {filterOptions} = useFilterOptions()

    const setMapData = (value) => {
        dispatch(setFieldValueOfMap({
            field: 'mapData',
            value
        }))
    }

    useEffect(() => {
        if(!isIFrame) {
            const UC = new Usercentrics(process.env.REACT_APP_COOKIE_BOT_ID)
            UC.init().then((initialUIValues)=>{})
        }
    }, [])

    const triggerFetch = async() => {
        setIsLoading(true)
        const query = buildQuery(filterOptions)
        cancelRequests()

        getMapData(coordinates, bounds, query)
            .then((data) => {
                if (typeof data !== 'undefined' && data.length) {
                    setMapData(data)
                } else {
                    setMapData([])
                }
            })
            .finally(() => {
                setIsLoading(false)
                setHasChanges(false)
            })
    }

    useEffect(() => {
        if (Object.keys(bounds).length && coordinates && hasChanges) {
            triggerFetch()
        }
        setHasChanges(false)
    }, [hasChanges])

    useEffect(() => {
        triggerFetch()
    }, [filterChanged])

    const setLocationsList = (value: ILocationsList | []) => {
        dispatch(setFieldValueOfLocation({
            field: 'locationsList',
            value
        }))
    }

    const {selectedPlace} = useSelectedPlace()
    const setPlacesList = (value: IPlacesList | []) => {
        dispatch(setFieldValueOfLocation({
            field: 'placesList',
            value
        }))
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Grid container style={{width: '100%'}}>
                    <Grid item xs={12} md={toggleLocationsList ? 4 : 0}>
                        <LocationDetail/>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Map showLocationsList={toggleLocationsList}/>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </>
    )
}

export default App