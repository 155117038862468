import axios from 'axios'
import config from './../config.js'
import { currentStatesMock } from './mocks'
import {getParamValue, buildQuery, getBearerToken} from "../utils"
import {IFilterOptions} from "../interface/filterOptions"
import Cookies from "universal-cookie"
const cookies = new Cookies()
let CancelToken, source
let ListCancelToken, ListSource

// const API_URL = 'https://api.greenway.sk/api'
const API_URL = getParamValue('chargingSessionLinkAPI') || config.chargingSessionLinkAPI

function getLanguage() {
    if(window.location.href.indexOf('/en/') > -1 || window.location.href.indexOf('/EN/') > -1) {
        return 'en'
    }
    if(window.location.href.indexOf('/sk/') > -1 || window.location.href.indexOf('/SK/') > -1) {
        return 'sk'
    }
    const lang = typeof cookies.get('gw_lang') !== 'undefined' ? cookies.get('gw_lang') : getParamValue('lang') || localStorage.getItem('lang') || 'en'
    return lang ? lang : process.env.REACT_APP_I18N_LOCALE || 'en'
}

interface ICoordinates {
    lat: number,
    lng: number
}

interface IBounds {
    ne: {
        lat: number,
        lng: number
    },
    sw: {
        lat: number,
        lng: number
    }
}

const getTranslations = async (lang: string) => {
    try {
        const response = await axios.get(`${API_URL}/translations/all/${lang}`)

        const appVersion = response.headers['x-version-app']
        const cookiesAppVersion = cookies.get('appVersion') || null
        if(cookiesAppVersion === null || cookiesAppVersion  !== appVersion) {
            cookies.set('appVersion', appVersion)
        }

        return response.data
    } catch (e) {
        if (axios.isCancel(e)) {
            throw(e)
        }
    }
}

const resetListToken = () => {
    ListCancelToken = axios.CancelToken
    ListSource = ListCancelToken.source()
}

const resetToken = () => {
    CancelToken = axios.CancelToken
    source = CancelToken.source()
}

const cancelRequests = () => {
    source?.cancel('Request canceled Operation canceled by the user.')
}

const getHeaders = () => {
    let headers = {
        'Accept-Language': getLanguage(),
        'Content-Type': 'application/json'
    }
    const token = getBearerToken()
    if(token) {
        headers['Authorization'] = `Bearer ${token}`
    }
    return headers
}

const getMapData = async (coordinates: ICoordinates, bounds: IBounds, query: string) => {
    try {
        // request
        // bl_latitude: sw.lat,
        // tr_latitude: ne.lat,
        // bl_longitude: sw.lng,
        // tr_longitude: ne.lng
        resetToken()

        const multiplier = 1.25
        if(bounds?.sw?.lat) {
            const {data} = await axios.get(
                `${API_URL}/location/map?${query}`, {
                    cancelToken: source.token,
                    headers: {
                        ...getHeaders()
                    },
                    params: {
                        spanLat: Math.abs(bounds.sw.lat - bounds.ne.lat) * multiplier,
                        spanLng: Math.abs(bounds.sw.lng - bounds.ne.lng) * multiplier,
                        latitude: coordinates.lat,
                        longitude: coordinates.lng
                    }
                })
            return data
        }
    } catch (e) {
        console.log(e)
    }
}

const fetchCommandState = async () => {
    try {
        if(getBearerToken()) {
            // clear url
            let url = new URL(window.location.href)
            window.history.pushState({}, null, url)
            return await axios.post(`${API_URL}/command/charging/state`, {}, {
                headers: {
                    ...getHeaders()
                }
            }).then(({data}) => {
                return data
            }).catch((e) => {
                throw e
            })
        } else {
            // return currentStatesMock
            return null
        }
    } catch (e) {
        if (axios.isCancel(e)) {
            throw(e)
        }
    }
}

const fetchCurrentStates = async () => {
    try {
        // return currentStatesMock
        if(getBearerToken()) {
            // clear url
            let url = new URL(window.location.href)
            window.history.pushState({}, null, url)

            return await axios.get(`${API_URL}/command/charging/states`, {
                headers: {
                    ...getHeaders()
                }
            }).then(({data}) => {
                return data
            }).catch((e) => {
                return []
            })
        } else {
            // return currentStatesMock
            return []
        }
    } catch (e) {
        if (axios.isCancel(e)) {
            throw(e)
        }
    }
}

const searchLocationsList = async (query: string) => {
    try {
        const {data} = await axios.get(
            `${API_URL}/location/list?${query}`, {
                headers: {
                    ...getHeaders()
                }
            })
        return data
    } catch (e) {
        if (axios.isCancel(e)) {
            throw(e)
        }
    }
}

const getLocationsList = async (search: string, coordinates: { lat: number, lng: number }, page: number, limit: number, filterOptions: IFilterOptions) => {
    try {
        resetListToken()

        if (coordinates.lat && coordinates.lng) {
            const query = buildQuery(filterOptions)

            let params = {
                page,
                limit,
                latitude: coordinates.lat,
                longitude: coordinates.lng
            }

            if(search) {
                const searchQuery = {
                    q: search
                }
                params = {
                    ...searchQuery,
                    ...params
                }
            }

            const {data} = await axios.get(
                `${API_URL}/location/list?${query}`, {
                    cancelToken: ListSource.token,
                    headers: {
                        ...getHeaders()
                    },
                    params
                })
            return data.locations
        }
    } catch (e) {
        console.log(e)
    }
}

const getLocationDetail = async (id: number) => {
    try {
        const {data} = await axios.get(
            `${API_URL}/location/${id}`, {
                headers: {
                    ...getHeaders()
                }
            })
        return data
    } catch (e) {
        console.log(e)
    }
}

// /charging/public/connectors?qrcode=https://m.intercharge.eu/evse/show?evseid=SK*GWIE0012*1*1&limit=10&page=1
const getConnectors = async (code: string, limit: number, page: number) => {
    resetToken()
    try {
        const {data} = await axios.get(
            `${API_URL}/charging/public/connectors?code=${code}&limit=${limit}&page=${page}`,
            {
                cancelToken: source.token
            })
        return data
    } catch (e) {
        if (axios.isCancel(e)) {
            throw(e)
        }
    }
}

export {
    getMapData,
    resetToken,
    getConnectors,
    cancelRequests,
    getTranslations,
    getLocationsList,
    getLocationDetail,
    fetchCommandState,
    fetchCurrentStates,
    searchLocationsList
}
