import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfBasic} from '../store/features/Basic'
import {RootState} from './../store'

import Cookies from 'universal-cookie'
const cookies = new Cookies()

const useSelectedLanguage = () => {
    const dispatch = useDispatch()
    const selectedLanguage = useSelector((state: RootState) => state.basic.selectedLanguage)
    const setSelectedLanguage = (value) => {

        // Cookies default expiration time and domain set
        const hostName = window.location.hostname
        const domain = hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1)
        const envExpire = Number(process.env.REACT_APP_COOKIE_LANG_EXPIRATION) || 400
        const maxAge = 3600 * 24 * envExpire
        cookies.set(
            'gw_lang',
            value,
    {
                domain,
                maxAge
            })

        dispatch(setFieldValueOfBasic({
            field: 'selectedLanguage',
            value
        }))
    }

    return {
        selectedLanguage,
        setSelectedLanguage
    }
}

export default useSelectedLanguage